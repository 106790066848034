// @font-face {
//   font-family:'DIN';
//    src: url('../fonts/DIN-Bold.otf') format('opentype');
// }
@import url("https://use.typekit.net/bee8cgn.css");

.has-mobile-nav body.user_mode {
   padding-top: 10px;
}

.user_mode #siteContainer {
   padding: 0;

   .col-md-6 {
      width: 50%;
      float: left;
   }

}

.user_mode #siteContainer .yieldPageContent {
   padding-top: 0;
}


.user_mode .splash-banner {
   padding-top: 73px;
}



.user_mode .splash-bar {

   display: none;
}
// Tablet
@media only screen and (min-width: 768px) {

   .has-mobile-nav body.user_mode {
      padding-top: 44px;
   }

   .user_mode #siteContainer .yieldPageContent {
      padding-top: 28px;
   }

   .user_mode .splash-banner {
      padding-top: 304px;
   }

   .user_mode footer.snFooterContainer {
      margin-top: 75px;
   }
}
// Desktop
@media only screen and (min-width: 1024px) {

   .user_mode #siteContainer {
      max-width: 1200px;

      .splash-buttons .col-md-6 {
         .heroPhotoElement {
            max-width: 478px;
         }

         .right .heroPhotoElement {
            float: right;
         }
      }
   }

   .user_mode #siteContainer .yieldPageContent {
      padding-top: 20px;
   }

   .user_mode .splash-banner {
      padding-top: 267px;
   }

   .user_mode footer.snFooterContainer {
      margin-top: 0;
   }
}

// .site-background {
//   background-size: cover;
// }
html.nav-fixed .site-background {
    margin-top: initial;
    // bottom:initial;
}
html:not(.collapsed-mobile-nav):not(.has-sub-nav).nav-fixed .site-background {
  margin-top: 0!important;
}
.top-banner {
  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    // img {
    //       transition: all .3s ease-in-out;
    //       &:hover {
    //           transform: scale(1.2);
    //           // transition: all 2s ease-in;
    //       }
    //   }
  }
  .heroPhotoElement img {
    width: 50%;
    .has-mobile-nav & {
      width: 75%;
    }
  }
}

.team-logos {
  .user_mode & {
  // height: 100vh;
  // width: 100%;
}
a {
  color: black;
  &:hover {
    color: black;
  }
}
  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin-top: 40px;
    img {
          transition: all .3s ease-in-out;
          &:hover {
              transform: scale(1.2);
              // transition: all 2s ease-in;
          }
      }
    .heroPhotoElement img {
      width: 75%;
      height: auto;
      margin-bottom: 0;
    }
    .has-mobile-nav & {
      flex-direction: column;
      justify-content: center!important;
      .pageEl {
        flex: 1;
        flex-basis: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center!important;
        margin: 10px;
      }
    }
    .pageEl {
      display: flex;
      justify-content: center;
      margin-left: 1em;
      margin-right: 1em;
      // width: 20%;

    .pageElement {
      margin:0;
    }
    }
    .pageEl:first-of-type {
      .heroPhotoElement {
        // width: 25%;
        .user_mode & {
        margin-bottom: -5em;
        .has-mobile-nav & {
          margin-bottom: 0;
        }
        }
      }
      // flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      // height: 100px;
      height: auto;
      .has-mobile-nav & {
        flex-basis: unset;
      }
      // img {
      //   width: 15%;
      //   .has-mobile-nav & {
      //     width: 30%;
      //   }
      // }
    }
    .pageEl:nth-child(2) {
      flex: 1;
      align-self: flex-end;
      .heroPhotoElement {
        .user_mode & {
        margin-bottom: -5em;
        .has-mobile-nav & {
          margin-bottom: 0;
        }
        }
      }
    }
    .pageEl:nth-child(3) {
      flex: 1;
      align-self: center;
      .heroPhotoElement {
        .user_mode & {
        margin-bottom: -5em;
        .has-mobile-nav & {
          margin-bottom: 0;
        }
        }
      }
    }
    .pageEl:nth-child(4) {
      // flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      .heroPhotoElement {
        // width: 25%;
      }
      .has-mobile-nav & {
        flex-basis: unset;
      }
    }

  }
  .heroPhotoElement {
    width: 14em;
    // height: auto;
  }

  .cutline {
    background-color: transparent;
    color: black;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 288px;
    max-width: 300px;
    text-align: center;
    margin: auto;
    font-family: 'din-2014', sans-serif;
    font-style: normal;
    font-weight: 700;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }
  .pageEl.no-logo {
     .textBlockElement{
       color: black;
       font-size: 1.5em;
       width: 100%;
       text-align: center;
       margin: auto;
       font-family: 'din-2014', sans-serif;
       font-style: normal;
       font-weight: 700;

    }

    }
  }


.team-logos .column.logo-3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .pageEl {
    flex:1;
    align-self: center;
    margin: 1em;
  }
  .heroPhotoElement {
  margin-bottom: 0!important;
  }
}
.team-logos .column.logo-5 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .pageEl {
    flex:1;
    flex-basis: 30%;
    align-self: center;
    margin: 1em;
  }
  .heroPhotoElement {
  margin-bottom:0!important;
  }
}

.team-logos .column.logo-6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .pageEl {
    flex:1;
    flex-basis: 30%;
    align-self: center;
    margin: 1em;
  }
  .heroPhotoElement {
  margin-bottom: 0!important;
  }
}

.team-logos .column.logo-plus {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .pageEl {
    flex:1;
    flex-basis: 25%;
    align-self: center;
  }
  .heroPhotoElement {
  margin-bottom: 0!important;
  }
}
